:root {
    --primary: #FF6B35;
    --blue: #3a99fe;
    --red: #F65857;
    --green: #4BB543;
    --orange: #6b327f;
    --black: #000000;
    --white: #ffffff;
}

body {
    margin: 0;
    padding: 0;
    //font-family: sans-serif;
    //font-family: 'Roboto';
}
